import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CustomFieldArrSelect from "../../components/FormSelect";
import CustomInput from "../../components/FormInput";
import CustomRadioInput from "./CustomRadioInput";
// import Dropdown from "../../components/Dropdown";
import { Button, message, notification, Select, Spin, Upload } from "antd";
import {
  GET_CLASSES_AND_DIVISIONS,
  INSERT_PARENT_STUDENT_RELATIONSHIP_UPDATE_NAME,
  INSERT_STUDENTS_ONE,
  ADD_STUDENT_ADDRESS,
  UPDATE_STUDENT_ADDRESS_RELATIONSHIP,
} from "./graphql";
import { GET_DEFAULT_CITY } from "../settingsView/graphql";
import { getSchoolID } from "../../shared/getSchoolID";
import _ from "lodash";
import { getSortedClasses, getSortedClassesAndDivisions } from "../../helpers";
import CustomAntdDatePicker from "./CustomDatePicker";
import { v4 as uuid } from "uuid";
import { getS3Image, getS3Bucket } from "../../shared/s3";
import {
  DATE_FORMATS,
  DEFAULT_STUDENT_CITY,
  schoolMediumList,
} from "../../utils/constants";
import useDownloadAdmissionForm from "../../hooks/useDownloadAdmissionForm";
import useFilterOptions from "../../hooks/useFilterOptions";
import useTracking from "../../hooks/useTracking";

const parentRelationship = ["Mother", "Father", "Guardian"];
const { Option } = Select;
const imagesS3Bucket = getS3Bucket();

const FormCard = ({ children, final, onSaveDraft }) => {
  return (
    <>
      <div className="w-3/4 border-2 bg-white rounded-md mb-6 pt-6">
        {children}
      </div>
    </>
  );
};
const FormCol = ({ id, children, overrideClassnames }) => {
  return (
    <div id={id} className={`${overrideClassnames} flex flex-col flex-auto`}>
      {children}
    </div>
  );
};
const FormRow = ({ children, overrideClassnames }) => {
  return (
    <div
      className={`${overrideClassnames} flex flex-row justify-start gap-x-8 mb-4 px-6`}
    >
      {children}
    </div>
  );
};
const FormSideSection = ({ title, description, children }) => {
  return (
    <div className=" w-1/4 text-left ">
      <p className="text-lg leading-6 font-medium text-gray-900  ">{title}</p>
      <p className="mt-1 text-sm leading-5 font-normal text-gray-500 w-8/12  ">
        {description}
      </p>
      {children}
    </div>
  );
};

const AddStudent = ({ onSuccess }) => {
  const [loader, setLoader] = useState(false);
  const [classData, setclassdata] = useState([]);
  const [divisionData, setdivisiondata] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedAdmittedDivision, setSelectedAdmittedDivision] =
    useState(null);
  const [divisionList, setDivisionList] = useState([]);
  const [division, setDivision] = useState(null);
  const [admittedClassDiv, setAdmittedClassDiv] = useState(null);
  const [hasImage, setHasImage] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [admissionFormMedium, setAdmissionFormMedium] = useState("");
  const { trackEvent } = useTracking();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    trigger,
    setError,
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm({
    criteriaMode: "all",
  });
  const classValue = watch("classValue");
  const divValue = watch("divValue");
  const admittedClassValue = watch("admittedClassValue");
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "contactDetails", // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  );
  const { data: filterOptions } = useFilterOptions(
    {
      schoolId: getSchoolID(),
      entity: "addStudent",
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  //Student Address
  const [addAddress] = useMutation(ADD_STUDENT_ADDRESS);
  const [updateAddressRelationship] = useMutation(
    UPDATE_STUDENT_ADDRESS_RELATIONSHIP
  );

  const [
    getDefaultCity,
    { loading: cityLoading, error: cityError, data: cityData },
  ] = useLazyQuery(GET_DEFAULT_CITY);

  const [insertStudents] = useMutation(INSERT_STUDENTS_ONE);
  const [insertParentStudent] = useMutation(INSERT_PARENT_STUDENT_RELATIONSHIP_UPDATE_NAME);
  const { loading, error } = useQuery(GET_CLASSES_AND_DIVISIONS, {
    variables: {
      schoolId: getSchoolID(),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (classd) => {
      let classLists = [];
      let divList = [];
      classd?.classes.map((xx) => {
        classLists.push({
          value: xx.id,
          label: `Class: ${xx.class_name}`,
        });
        xx.divisions.map((divz) =>
          divList.push({
            value: divz.id,
            divs: divz.division_name,
            label: `Division: ${divz.division_name}`,
            class_id: xx.id,
          })
        );
      });
      setclassdata(classLists);
      setdivisiondata(divList);
    },
  });

  const { isFetching: admissionFormDownloading } = useDownloadAdmissionForm(
    {
      medium: admissionFormMedium,
      studentDetails: getValues(),
      schoolId: getSchoolID(),
    },
    {
      onSettled: () => {
        setAdmissionFormMedium("");
      },
    }
  );

  useEffect(() => {
    getDefaultCity({
      variables: { cityName: DEFAULT_STUDENT_CITY },
    });
  }, []);

  let addedBy = JSON.parse(localStorage.getItem("claims"))["x-hasura-user-id"];

  const nameId = "managestudents-addstudent-parentname";
  const contactId = "managestudents-addstudent-parentcontact";

  const onSubmit = (data) => {
    if (selectedDivision !== null && selectedClass !== null) {
      trackEvent("Add Student");
      setLoader(true);
      let payload = {
        aadhaar: data.aadhaar,
        caste: data.caste,
        division_id: parseInt(selectedDivision),
        dob: data.dateOfBirth
          ? moment(data.dateOfBirth).format(DATE_FORMATS.YYYYMMDD)
          : null,
        enroll_date: data.enrolDate
          ? moment(data.enrolDate).format(DATE_FORMATS.YYYYMMDD)
          : null,
        first_name: data.firstName,
        is_draft: false,
        last_name: data.lastName,
        middle_name: data.middleName,
        religion: data.religion,
        admitted_class: selectedAdmittedDivision,
        paying_or_free: data.payingOrFree,
        last_school: data.lastSchool,
        place_of_birth: data.placeOfBirth,
        gender: data.gender,
        gr_no: data.grNo || null,
        gr_set: data.grSet,
        rte: data.rte,
        category: data.category,
        father_name: data.fatherName,
        mother_name: data.motherName,
        remarks: data.remarks,
        left_class: data.leftClass,
        conduct: data.conduct,
        student_progress: data.studentProgress,
        student_ref: data.studentRef,

        height_term_one: data.heightTermOne,
        weight_term_one: data.weightTermOne,

        height_term_two: data.heightTermTwo,
        weight_term_two: data.weightTermTwo,

        parents_occupation: data.parentsOccupation,
        mother_tongue: data.motherTongue,

        address_id: null,

        sub_caste: data?.subcaste,
        is_minority: data?.minority,
        is_below_poverty_line: data?.belowPovertyLine,
        mother_occupation: data?.motherOccupation,
        no_of_sisters: data?.noOfSisters?.length ? parseInt(data?.noOfSisters, 10) : 0,
        no_of_brothers: data?.noOfBrothers?.length ? parseInt(data?.noOfBrothers, 10) : 0,
        total_family_members: data?.totalFamilyMembers?.length ? parseInt(data?.totalFamilyMembers, 10) : 0,
        is_handicap: data?.handicap,
        register_book_no: data?.registerBookNo,
        last_school_transfer_date: data?.lastSchoolTransferDate,
        nationality: data?.nationality,
        blood_group: data?.bloodGroup,
        father_education: data?.fatherEducation,
        mother_education: data?.motherEducation,
        last_school_class: data?.lastSchoolClass,

        school_id: getSchoolID(),
        division_students: {
          data: {
            division_id: parseInt(selectedDivision),
          },
        },
        audit_trails: {
          data: {
            added_by: addedBy,
            changes: "test",
          },
        },
      };
      if (hasImage) {
        payload.image = imageID;
      }

      const addressPayload = {
        add1: data.add1,
        add2: data.add2,
        pincode: data.pincode,
        cityValue: data.cityValue,
      };

      let shouldCreateParents = data.contactDetails.length > 0;
      if (selectedDivision !== null && selectedClass !== null) {
        if (shouldCreateParents) {
          createinFirebase(
            data.contactDetails,
            payload,
            shouldCreateParents,
            addressPayload
          );
        } else {
          insertStudentIntoHasura(
            [],
            payload,
            [],
            shouldCreateParents,
            addressPayload
          );
        }
      } else {
        setLoader(false);
        console.error("select class division combo");
      }
    } else {
      setError(
        "firstName",
        {
          types: {
            required: "This is required",
            minLength: "This is minLength",
          },
        },
        { shouldFocus: true }
      );
    }
  };
  const createinFirebase = (
    payloadforParents,
    payloadFromSubmit,
    shouldCreateParents,
    addressPayload
  ) => {
    let userPayload = [];
    payloadforParents.map((usr) => {
      userPayload.push({
        phone: `+91${usr.contactInfo}`,
        first_name: usr.fullName,
        school_id: getSchoolID(),
        role_id: 3,
      });
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_FUNCTION_ENDPOINT}/createUsers`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { data: userPayload },
    };
    axios(config)
      .then((res) => {
        if (res?.data?.successArray?.length > 0) {
          let setAtt = [];
          const findParent = (contactInfo) => {
            let finddd = res?.data?.successArray?.find(
              (obj) => obj.phoneNumber === `+91${contactInfo}`
            );
            if (finddd) {
              return finddd;
            }
            return null;
          };
          payloadforParents.map((plp) => {
            const fbUser = findParent(plp.contactInfo);
            if (fbUser) {
              setAtt.push({
                ...plp,
                user_id: fbUser.uid,
                update_name: fbUser.state === "existing"
              });
            }
          });
          insertStudentIntoHasura(
            setAtt,
            payloadFromSubmit,
            res.data.successArray,
            shouldCreateParents,
            addressPayload
          );
        } else {
          notification["error"]({
            message: "Something went wrong",
            description: " Could not create parents",
            duration: 1.5,
          });
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  };
  const insertStudentIntoHasura = (
    payloadforParents,
    payloadFromSubmit,
    parentsFromFirebase,
    shouldCreateParents,
    addressPayload
  ) => {
    insertStudents({ variables: { objects: payloadFromSubmit } })
      .then((response) => {
        notification["success"]({ message: "Student added", duration: 1.5 });

        if (shouldCreateParents) {
          insertParentStudentRelationship(
            payloadforParents,
            payloadFromSubmit,
            parentsFromFirebase,
            response?.data?.insert_students_one?.id
          );
        } else {
          onSuccess();
          setLoader(false);
        }

        sendAddress(response?.data?.insert_students_one?.id, addressPayload);
      })
      .catch((reserror) => {
        setLoader(false);
        console.error(reserror);
      });
  };
  const insertParentStudentRelationship = (
    payloadforParents,
    payloadFromSubmit,
    parentsFromFirebase,
    studentID
  ) => {
    let finalPayload = [];
    let userUpdate = [];
    payloadforParents.map((parent) => {
      finalPayload.push({
        student_id: studentID,
        user_id: parent.user_id,
        relation: parent.contactRelationship,
      });
      if(parent.update_name) {
        userUpdate.push({
          _set: { first_name: parent.fullName },
          where: { auth_id: { _eq: parent.user_id } },
        });
      }
    });

    insertParentStudent({ variables: { objects: finalPayload, userUpdate } })
      .then((response) => {
        notification["success"]({ message: "Parents added", duration: 1.5 });
        setTimeout(() => {
          setLoader(false);
          onSuccess();
        }, 1500);
      })
      .catch((reserror) => {
        setLoader(false);
        console.error(reserror.response.data);
      });
  };

  const sendAddress = (studentId, payloadFromSubmit) => {
    const cityId = cityData?.cities ? cityData?.cities[0].id : false;
    if (isNaN(cityId) || cityId == 0) {
      notification["error"]({
        message: "Something went wrong",
        description: "Please Reload The Page",
        duration: 1.5,
      });
      return;
    }
    if (payloadFromSubmit.add1?.length > 0) {
      const address = {
        address_line_one: payloadFromSubmit.add1 || "",
        city_id: cityId,
      };
      addAddress({ variables: { address: address } })
        .then((response) => {
          sendAddressRelationship(
            studentId,
            response?.data?.insert_addresses?.returning[0]?.id
          );
          setLoader(false);
          onSuccess();
        })
        .catch((reserror) => {
          console.error(reserror);
          setLoader(false);
        });
    }
  };

  const sendAddressRelationship = (studentId, addressId) => {
    updateAddressRelationship({
      variables: {
        studentId,
        addressId,
        addedBy,
      },
    })
      .then((response) => {
        setLoader(false);
        onSuccess();
      })
      .catch((reserror) => {
        console.error(reserror);
        setLoader(false);
      });
  };

  const onSaveAsDraft = () => {
    let values = getValues();
    clearErrors();

    if (values.firstName.length < 2) {
      setError(
        "firstName",
        {
          types: {
            required: "This is required",
            minLength: "Enter at least 2 characters",
          },
        },
        { shouldFocus: true }
      );
    } else if (_.isNil(values.classValue) || _.isNil(values.divValue)) {
      if (_.isNil(values.classValue)) {
        setError(
          "classValue",
          {
            type: "manual",
            name: "classValue",
            message: "required",
          },
          { shouldFocus: true }
        );
      }
      if (_.isNil(values.divValue)) {
        setError(
          "divValue",
          {
            type: "manual",
            name: "divValue",
            message: "required",
          },
          { shouldFocus: true }
        );
      }
    } else {
      saveDraftAPI(values);
    }
  };
  const saveDraftAPI = (draftData) => {
    setLoader(true);
    let payload = {
      aadhaar: draftData.aadhaar,
      caste: draftData.caste,
      first_name: draftData.firstName,
      is_draft: true,
      last_name: draftData.lastName,
      middle_name: draftData.middleName,
      religion: draftData.religion,
      gender: draftData.gender,

      admitted_class: selectedAdmittedDivision,
      paying_or_free: draftData.payingOrFree,
      last_school: draftData.lastSchool,
      place_of_birth: draftData.placeOfBirth,
      gr_no: draftData.grNo || null,
      gr_set: draftData.grSet,
      rte: draftData.rte,
      category: draftData.category,
      father_name: draftData.fatherName,
      mother_name: draftData.motherName,
      remarks: draftData.remarks,
      left_class: draftData.leftClass,
      conduct: draftData.conduct,
      student_progress: draftData.studentProgress,
      student_ref: draftData.studentRef,
      school_id: getSchoolID(),

      height_term_one: draftData.heightTermOne,
      weight_term_one: draftData.weightTermOne,

      height_term_two: draftData.heightTermTwo,
      weight_term_two: draftData.weightTermTwo,

      parents_occupation: draftData.parentsOccupation,
      mother_tongue: draftData.motherTongue,

      sub_caste: draftData?.subcaste,
      is_minority: draftData?.minority,
      is_below_poverty_line: draftData?.belowPovertyLine,
      mother_occupation: draftData?.motherOccupation,
      no_of_sisters: draftData?.noOfSisters || null,
      no_of_brothers: draftData?.noOfBrothers || null,
      total_family_members: draftData?.totalFamilyMembers || null,
      is_handicap: draftData?.handicap,
      register_book_no: draftData?.registerBookNo,
      last_school_transfer_date: draftData?.lastSchoolTransferDate,
      nationality: draftData?.nationality,
      blood_group: draftData?.bloodGroup,
      father_education: draftData?.fatherEducation,
      mother_education: draftData?.motherEducation,
      last_school_class: draftData?.lastSchoolClass,

      address_id: null,
    };
    const addressPayload = {
      add1: draftData.add1,
      add2: draftData.add2,
      pincode: draftData.pincode,
      cityValue: draftData.cityValue,
    };
    if (draftData.dateOfBirth) {
      payload.dob = moment(draftData.dateOfBirth).format(DATE_FORMATS.YYYYMMDD);
    }
    if (draftData.enroll_date) {
      payload.enroll_date = moment(draftData.enrolDate).format(
        DATE_FORMATS.YYYYMMDD
      );
    }
    if (selectedDivision !== null) {
      payload.division_id = selectedDivision;
    }
    if (hasImage) {
      payload.image = imageID;
    }
    insertStudents({ variables: { objects: payload } })
      .then((response) => {
        sendAddress(response?.data?.insert_students_one?.id, addressPayload);
        notification["success"]({
          message: "Student saved to draft",
          duration: 1.5,
        });
        setLoader(false);
        onSuccess();
      })
      .catch((reserror) => {
        setLoader(false);
        console.error(reserror);
      });
  };
  const renderImage = () => {
    if (hasImage) {
      return (
        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
          <img
            src={imageURL}
            alt="avatar"
            className="w-full h-full object-cover rounded-md"
          />
        </span>
      );
    }
    return (
      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  };
  const handleUpload = async (file, onSuccess) => {
    const fileId = uuid();
    // get file extension
    const fileExtension = file.name.split(".").pop();
    const key = `${process.env.REACT_APP_S3_BUCKET_FOLDER}/${fileId}.${fileExtension}`;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
    };
    imagesS3Bucket.putObject(params).send((err, data) => {
      if (err) {
        // handle the error here
        console.error("s3 err", err);
      } else {
        // handle the success here
        onSuccess(data.response, file);
        //  store file id in school instead of image url
        const imageKey = `${fileId}.${fileExtension}`;
        // update image in school
        setHasImage(true);
        setImageID(imageKey);
        setImageURL(getS3Image(imageKey));
      }
    });
  };
  const handleDownloadAdmissionForm = async (medium) => {
    setAdmissionFormMedium(medium.toLowerCase());
  };
  const uploadProps = {
    customRequest({ file, onSuccess }) {
      return handleUpload(file, onSuccess);
    },
  };
  if (loading) return "Loading...";

  return (
    <Spin spinning={loader || admissionFormDownloading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          <FormSideSection
            title="Student Details"
            description="Personal information of the child"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <div>
                  <label className="block text-sm font-semibold text-gray-700 text-left">
                    Photo (Optional)
                  </label>
                  <div className="mt-1 flex items-center">
                    {renderImage()}
                    <Upload
                      {...uploadProps}
                      beforeUpload={(file) => {
                        // check if file is an jpeg or png
                        if (
                          file.type !== "image/jpeg" &&
                          file.type !== "image/png"
                        ) {
                          message.error("Only jpeg or png images are allowed!");
                          return false;
                        }
                        // check if file size is less than 2MB
                        if (file.size > 2000000) {
                          message.error("File size should be less than 2MB!");
                          return false;
                        }
                        return true;
                      }}
                      showUploadList={false}
                      className="ml-5 bg-white py-2 px-3 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Button
                        id="managestudents-add-upload"
                        style={{
                          borderRadius: 6,
                          fontWeight: "bold",
                          color: "#374151",
                        }}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-firstname"}
                  name="firstName"
                  placeholder="firstName"
                  label="First Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "can't be longer than 20 characters",
                    },
                    required: "required",
                  }}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-middlename"}
                  name="middleName"
                  placeholder="middleName"
                  label="Middle Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "can't be longer than 20 characters",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-lastname"}
                  name="lastName"
                  placeholder="lastName"
                  label="Last Name"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "can't be longer than 20 characters",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol overrideClassnames="col-span-1">
                <CustomInput
                  id={"managestudents-addstudent-aadhaar"}
                  name="aadhaar"
                  placeholder="aadhaar"
                  label="Aadhaar Number"
                  register={register}
                  rules={{
                    maxLength: {
                      value: 12,
                      message:
                        "Aadhaar number is a 12 digit number. You seem to have entered too many.",
                    },
                    minLength: {
                      value: 12,
                      message: "Aadhaar number is atleast 12 digits long.",
                    },
                  }}
                  errors={errors}
                />
              </FormCol>

              <FormCol overrideClassnames="col-span-2">
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Gender"}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>

                <div
                  id={"managestudents-addstudent-gender"}
                  className="flex flex-row"
                >
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-male"}
                      name="gender"
                      value="M"
                      label="Male"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-female"}
                      name="gender"
                      value="F"
                      label="Female"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-trans"}
                      name="gender"
                      value="T"
                      label="Transgender"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>

                  <div className="flex flex-col w-22 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-na"}
                      name="gender"
                      value="NA"
                      label="Not Disclosed"
                      register={register}
                      type="radio"
                      errors={errors}
                      required
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol id={"managestudents-addstudent-dob"}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={{ required: "required" }}
                  render={({ field }) => (
                    <>
                      <CustomAntdDatePicker
                        fieldData={{ ...field }}
                        name="dateOfBirth"
                        placeholder="dateOfBirth"
                        label="Date of birth"
                        register={register}
                        errors={errors}
                        onChange={(dob) => setValue(`dateOfBirth`, dob)}
                        required
                      />
                    </>
                  )}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-place"}
                  name="placeOfBirth"
                  label="Place of birth"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol id={"managestudents-addstudent-bloodgroup"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Blood Group
                </label>
                <Controller
                  name="bloodGroup"
                  control={control}
                  render={() => (
                    <Select
                      className="customised-antd-selector-controlled"
                      onChange={(value) => {
                        setValue("bloodGroup", value);
                      }}
                      optionFilterProp="children"
                      showSearch
                      size="large"
                      allowClear
                    >
                      {filterOptions?.bloodGroup?.map((group) => (
                        <Option key={group}>{group}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Religion
                </label>
                <Select
                  {...register("religion")}
                  className="customised-antd-selector-controlled"
                  onChange={(value) => {
                    setValue("religion", value);
                  }}
                  optionFilterProp="children"
                  showSearch
                  size="large"
                  allowClear
                >
                  {(filterOptions?.religion || [])?.map(({ value }) => (
                    <Option key={value}>{value}</Option>
                  ))}
                </Select>
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-caste"}
                  name="caste"
                  placeholder="caste"
                  label="Caste"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-caste"}
                  name="subcaste"
                  placeholder="subcaste"
                  label="Sub Caste"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Category
                </label>
                <Select
                  {...register("category")}
                  className="customised-antd-selector-controlled"
                  onChange={(value) => {
                    setValue("category", value);
                  }}
                  optionFilterProp="children"
                  showSearch
                  size="large"
                  allowClear
                >
                  {(filterOptions?.categories || [])?.map(({ value }) => (
                    <Option key={value}>{value}</Option>
                  ))}
                </Select>
              </FormCol>

              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-mothertongue"}
                  name="motherTongue"
                  label="Mother Tongue"
                  register={register}
                  errors={errors}
                />
              </FormCol>

              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-nationality"}
                  name="nationality"
                  label="Nationality"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Minority"}
                </label>

                <div
                  id={"managestudents-addstudent-minority"}
                  className="flex flex-row"
                >
                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-minority"}
                      name="minority"
                      value="True"
                      label="Yes"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-minority"}
                      name="minority"
                      value="False"
                      label="No"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Below Poverty Line (BPL)"}
                </label>

                <div
                  id={"managestudents-addstudent-belowPovertyLine"}
                  className="flex flex-row"
                >
                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-belowPovertyLine"}
                      name="belowPovertyLine"
                      value="True"
                      label="Yes"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-belowPovertyLine"}
                      name="belowPovertyLine"
                      value="False"
                      label="No"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Family information"
            description="Basic Information of family"
          />
          <FormCard>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-fathersname"}
                  name="fatherName"
                  label="Father's Name"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-parentsoccupation"}
                  name="parentsOccupation"
                  label="Father's Occupation"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-fathereducation"}
                  name="fatherEducation"
                  label="Father's Education"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-mothersname"}
                  name="motherName"
                  label="Mother's Name"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-motheroccupation"}
                  name="motherOccupation"
                  label="Mother's Occupation"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-motherducation"}
                  name="motherEducation"
                  label="Mother's Education"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-sisters"}
                  name="noOfSisters"
                  label="No. of Sisters"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-brothers"}
                  name="noOfBrothers"
                  label="No. of Brothers"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-totalfamilymembers"}
                  name="totalFamilyMembers"
                  label="Total Family Members"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Physical Details"
            description="Height and Weight will be shown in the Progress Report"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <label className="text-gray-900 font-bold text-md leading-5 text-left mb-2">
                  First Term
                </label>
                <CustomInput
                  id={"managestudents-addstudent-heighttermone"}
                  name="heightTermOne"
                  label="Height (cm)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-900 font-bold text-md leading-5 text-left mb-2">
                  Second Term
                </label>
                <CustomInput
                  id={"managestudents-addstudent-heighttermtwo"}
                  name="heightTermTwo"
                  label="Height (cm)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-weighttermOne"}
                  name="weightTermOne"
                  label="Weight (kg)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-weighttermtwo"}
                  name="weightTermTwo"
                  label="Weight (kg)"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>

            <FormRow>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mb-2">
                  {"Handicap"}
                </label>

                <div
                  id={"managestudents-addstudent-handicap"}
                  className="flex flex-row"
                >
                  <div className="flex flex-col w-20 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-handicap"}
                      name="handicap"
                      value="True"
                      label="Yes"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-handicap"}
                      name="handicap"
                      value="False"
                      label="No"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
          </FormCard>
        </div>
        <div className="flex">
          <FormSideSection
            title="Parent Contact Details"
            description="Contact information of parents or guardians"
          />
          <FormCard>
            {fields.map((field, fieldIndex) => (
              <FormRow key={field.id} overrideClassnames="items-end">
                <FormCol>
                  <CustomInput
                    id={nameId + fieldIndex}
                    key={field.id} // important to include key with field's id
                    register={() =>
                      register(`contactDetails.${fieldIndex}.fullName`, {
                        required: true,
                      })
                    }
                    errors={errors}
                    label="Full Name"
                    required
                  />
                </FormCol>
                <FormCol>
                  <CustomInput
                    id={contactId + fieldIndex}
                    key={field.id} // important to include key with field's id
                    name="contactInfo"
                    placeholder="contactInfo"
                    label="Contact information"
                    register={() =>
                      register(`contactDetails.${fieldIndex}.contactInfo`, {
                        required: true,
                        pattern: /^[6-9]\d{9}$/i,
                      })
                    }
                    errors={errors}
                    required
                  />
                </FormCol>
                <FormCol>
                  <CustomFieldArrSelect
                    id="managestudents-addstudent-contactrelation"
                    key={field.id} // important to include key with field's id
                    options={parentRelationship}
                    name="contactRelationship"
                    placeholder="contactRelationship"
                    label="Contact relationship"
                    register={() =>
                      register(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        {
                          required: true,
                        }
                      )
                    }
                    errors={errors}
                    defaultValue={`${field.contactRelationship}`}
                    onChange={(value) =>
                      setValue(
                        `contactDetails.${fieldIndex}.contactRelationship`,
                        value
                      )
                    }
                    required
                  />
                </FormCol>
                <FormCol>
                  <button
                    type="reset"
                    className="py-3 px-3 mr-2 justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100 "
                    onClick={() => remove(fieldIndex)}
                  >
                    Cancel
                  </button>
                </FormCol>
              </FormRow>
            ))}
            <FormRow>
              <button
                id="managestudents-addstudent-addcontact"
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    fullName: "",
                    contactInfo: "",
                    contactRelationship: "",
                  });
                }}
                className="p-2 rounded-md bg-white border border-gray-300 text-xs
              leading-4 font-medium shadow-sm  cursor-pointer "
              >
                {" "}
                Add new contact (optional)
              </button>
            </FormRow>
          </FormCard>
        </div>
        <div className="flex">
          <FormSideSection
            title="Additional Details"
            description="Overall conduct and other details"
          />
          <FormCard>
            <FormRow>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-refno"}
                  name="studentRef"
                  label="Student Reference Number"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-grset"}
                  name="grSet"
                  label="General Register Set"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-grno"}
                  name="grNo"
                  label="General Register Number"
                  register={register}
                  errors={errors}
                  required
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-registerbookno"}
                  name="registerBookNo"
                  label="Register Book No."
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mt-1 mb-1">
                  {"Paying or Free"}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left "></label>
                  }
                </label>

                <div className="flex flex-row">
                  <div className="flex flex-col w-18 pr-8">
                    <CustomRadioInput
                      id={"managestudents-addstudent-payingorfree-p"}
                      name="payingOrFree"
                      label="Paying"
                      value="Paying"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-payingorfree-f"}
                      name="payingOrFree"
                      label="Free"
                      value="Free"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>

              <FormCol>
                <label className="text-gray-700 font-semibold text-sm leading-5 text-left mt-1 mb-1">
                  {"Right to Education (RTE) "}{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left "></label>
                  }
                </label>

                <div className="flex flex-row">
                  <div className="flex flex-col w-18 pr-8">
                    <CustomRadioInput
                      id={"managestudents-addstudent-rte-y"}
                      name="rte"
                      label="Yes"
                      value="True"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>

                  <div className="flex flex-col w-18 pr-4">
                    <CustomRadioInput
                      id={"managestudents-addstudent-rte-n"}
                      name="rte"
                      label="No"
                      value="False"
                      register={register}
                      type="radio"
                      errors={errors}
                    />
                  </div>
                </div>
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-lastschool"}
                  name="lastSchool"
                  label="Last School Name"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol id={"managestudents-addstudent-lastschoolclass"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Last School Class
                </label>
                <Controller
                  name="lastSchoolClass"
                  control={control}
                  render={() => (
                    <Select
                      className="customised-antd-selector-controlled"
                      onChange={(value) => {
                        setValue(
                          "lastSchoolClass",
                          value.replace("Class: ", "")
                        );
                      }}
                      size="large"
                      allowClear
                      placeholder="Class: All"
                    >
                      {getSortedClasses(classData).map((classl) => (
                        <Option key={classl.label}>{classl.label}</Option>
                      ))}
                    </Select>
                  )}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol id={"managestudents-addstudent-lstd"}>
                <Controller
                  name="lastSchoolTransferDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <CustomAntdDatePicker
                        fieldData={{ ...field }}
                        name="lastSchoolTransferDate"
                        placeholder="lastSchoolTransferDate"
                        label="Last School Transfer Date"
                        register={register}
                        errors={errors}
                        onChange={(lsDate) =>
                          setValue(`lastSchoolTransferDate`, lsDate)
                        }
                      />
                    </>
                  )}
                />
              </FormCol>
              <FormCol id={"managestudents-addstudent-admitted"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Class and Division in which admitted{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left "></label>
                  }
                </label>
                <Controller
                  name="admittedClassValue"
                  control={control}
                  defaultValue={admittedClassValue}
                  render={({
                    field,
                    fieldState: {
                      invalid,
                      isTouched,
                      isDirty,
                      error: errorField,
                    },
                  }) => (
                    <>
                      <Select
                        className="customised-antd-selector"
                        value={admittedClassValue}
                        style={{ flex: 1, width: "100%", textAlign: "left" }}
                        onChange={(value) => {
                          setSelectedAdmittedDivision(value);
                          setAdmittedClassDiv(value);
                          setValue("admittedClassValue", value);
                        }}
                        size="large"
                        allowClear
                        placeholder="Select Class and Division"
                      >
                        {getSortedClassesAndDivisions(divisionData).map(
                          (div, idx) => (
                            <Option key={div.divs + idx}>{div.divs}</Option>
                          )
                        )}
                      </Select>
                      <>
                        <p className="text-left italic text-sm text-red-600 mt-1 ">
                          {errorField?.message}
                        </p>
                      </>
                    </>
                  )}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-conduct"}
                  name="conduct"
                  label="Conduct"
                  register={register}
                  errors={errors}
                />
              </FormCol>
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-remarks"}
                  name="remarks"
                  label="Remarks"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
            <FormRow overrideClassnames="grid grid-cols-2">
              <FormCol>
                <CustomInput
                  id={"managestudents-addstudent-progress"}
                  name="studentProgress"
                  label="Overall progress"
                  register={register}
                  errors={errors}
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>

        <div className="flex">
          <FormSideSection
            title="Enrollment Details"
            description="Date, Class and Division"
          />
          <FormCard final onSaveDraft={onSaveAsDraft}>
            <FormRow overrideClassnames="grid grid-cols-3">
              <FormCol id={"managestudents-addstudent-enroldate"}>
                <Controller
                  name="enrolDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <CustomAntdDatePicker
                        fieldData={{ ...field }}
                        name="enrolDate"
                        placeholder="enrolDate"
                        label="Enrollment date"
                        register={register}
                        errors={errors}
                        onChange={(dob) => setValue(`enrolDate`, dob)}
                      />
                    </>
                  )}
                />
              </FormCol>
              <FormCol id={"managestudents-addstudent-class"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Class{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>
                <Controller
                  name="classValue"
                  control={control}
                  defaultValue={classValue}
                  rules={{ required: "required" }}
                  render={({
                    field,
                    fieldState: {
                      invalid,
                      isTouched,
                      isDirty,
                      error: errorField,
                    },
                  }) => (
                    <>
                      <Select
                        className="customised-antd-selector-controlled"
                        value={classValue}
                        onChange={(value) => {
                          let filterDivsWithClassID = divisionData.filter(
                            (x) => x.class_id === parseInt(value)
                          );
                          setSelectedClass(value);
                          setSelectedDivision(null);
                          setDivision(null);
                          setDivisionList(filterDivsWithClassID);
                          setValue("classValue", value);
                        }}
                        size="large"
                        allowClear
                        placeholder="Class: All"
                      >
                        {getSortedClasses(classData).map((classl) => (
                          <Option key={classl.value}>{classl.label}</Option>
                        ))}
                      </Select>
                      <>
                        <p className="text-left italic text-sm text-red-600 mt-1 ">
                          {errorField?.message}
                        </p>
                      </>
                    </>
                  )}
                />
              </FormCol>
              <FormCol id={"managestudents-addstudent-division"}>
                <label className="text-gray-700 font-semibold text-sm leading-5 pb-1 text-left ">
                  Division{" "}
                  {
                    <label className="text-red-700 font-medium text-sm leading-5 pb-1 text-left ">
                      *
                    </label>
                  }
                </label>
                <Controller
                  name="divValue"
                  control={control}
                  defaultValue={divValue}
                  rules={{ required: "required" }}
                  render={({
                    field,
                    fieldState: {
                      invalid,
                      isTouched,
                      isDirty,
                      error: errorField,
                    },
                  }) => (
                    <>
                      <Select
                        className="customised-antd-selector-controlled"
                        value={divValue}
                        onChange={(value) => {
                          setSelectedDivision(value);
                          setDivision(value);
                          setValue("divValue", value);
                        }}
                        size="large"
                        allowClear
                        placeholder="Division: All"
                      >
                        {getSortedClassesAndDivisions(divisionList).map(
                          (div) => (
                            <Option key={div.value}>{div.label}</Option>
                          )
                        )}
                      </Select>
                      <>
                        <p className="text-left italic text-sm text-red-600 mt-1 ">
                          {errorField?.message}
                        </p>
                      </>
                    </>
                  )}
                />
              </FormCol>
            </FormRow>
          </FormCard>
        </div>
        <div className="px-4 text-right sm:px-6">
          <button
            id="managestudents-addstudent-cancel"
            type="reset"
            className="py-3 px-3 mr-2 justify-center items-center bg-white border border-gray-300 focus:ring-offset-pink-200  transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md cw-btn-100 "
            onClick={() => reset()}
          >
            Cancel
          </button>
          <button
            id="managestudents-addstudent-draft"
            className="py-3 px-4 inline-flex justify-center items-center  bg-white border border-gray-300 focus:ring-offset-pink-200 transition ease-in duration-200 text-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md mr-2 "
            onClick={(e) => {
              e.preventDefault();

              onSaveAsDraft();
            }}
          >
            Save as draft
          </button>
          {/*<div className="inline-flex mr-2">
            <Dropdown
              value={"Download Admission Form"}
              type="success"
              options={[schoolMediumList[0], schoolMediumList[1]]}
              onItemClick={handleDownloadAdmissionForm}
            />
          </div>*/}
          <button
            id="managestudents-addstudent-save"
            type="submit"
            className="inline-flex -mr-6 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </Spin>
  );
};
export default AddStudent;
